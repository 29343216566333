import { Injectable, WritableSignal, signal } from '@angular/core';

import {
  Shift,
  ShiftFilter,
  ShiftLink,
  ShiftResponse,
  ShiftRole,
  ShiftTotals,
  ShiftType,
} from './schedule.type';
import { SHIFT_MODEL, TOTALS_MODEL } from './schedule.model';
import { QueryUnique } from 'websocket';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  public queries(
    type: ShiftType,
    shift: Shift,
    filter?: ShiftFilter,
  ): QueryUnique {
    const queries = {
      ...SHIFT_MODEL[type].query,
      id: shift.id,
    };

    if (filter?.search) {
      const withQuery = [...(SHIFT_MODEL[type].query.with || [])];

      if (withQuery.length > 0) {
        const relation = (withQuery[0] as string).split(':');

        withQuery[0] = {
          relation: relation[0],
          queries: {
            fields: (relation[1] || '*').split(','),
            whereHas: {
              user: {
                search: {
                  value: filter?.search,
                  operator: 'scope',
                },
              },
            },
          },
        };
      }

      queries.with = withQuery;

      queries.lazy = {
        ...queries,
        ...SHIFT_MODEL[type].lazyQuery,
      };
    }

    return queries;
  }

  public shiftName(type: ShiftType, shift: Shift): string {
    switch (type) {
      case 'regular':
        return `${shift.start_time} - ${shift.end_time}`;
      case 'contractor':
        return shift.contractor!.name || '';
      default:
        return 'stambridge';
    }
  }

  public unassigned(
    totals: ShiftTotals,
    placeholder = 'Not Assigned',
  ): string[] {
    return Array.from(
      { length: Math.max(0, totals.required.total - totals.total.total) },
      () => placeholder,
    );
  }

  public totals(options: { shift: Shift; filtered: boolean }): ShiftTotals {
    const { shift, filtered } = { ...options };

    const total = { ...TOTALS_MODEL };
    const required = { ...total };

    if (!filtered) {
      for (const link of shift?.shift_links || []) {
        let role = link().data?.role || 'steward';
        if (role === 'steward' || role === 'supervisor') role = `${role}s`;

        if (role in total) {
          total[role as ShiftRole] += 1;
          total.total += 1;
        }
      }
    }

    (Object.keys(total) as Array<ShiftRole>).forEach((key) => {
      if (filtered) {
        total[key] = Number(shift[`${key}_total` as keyof Shift]) || total[key];
        total.total += total.sia + total.stewards + total.supervisors;
      }

      required[key] = Number(shift[key as keyof Shift]) || required[key];
      const supervisor = shift.supervisors || shift.supervisor || 0;
      required.total = required.sia + required.stewards + supervisor;
    });

    return { total, required };
  }

  public mergeTypes(options: {
    shift: Shift;
    data: ShiftResponse;
    type: ShiftType;
  }): WritableSignal<ShiftLink>[] {
    const { shift, data, type } = options;

    let links = shift.shift_links || [];

    if (data.shift_links) {
      links = [];

      data.shift_links.forEach((linkData) => {
        let link = shift.shift_links?.find((l) => l().id === data.id);

        const linkModel = {
          id: linkData.id,
          type: SHIFT_MODEL[type].shiftLinkType,
          data: linkData,
        };

        !link ? (link = signal(linkModel)) : link.set(linkModel);

        links.push(link);
      });
    }

    return links;
  }
}
