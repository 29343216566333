import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PusherEvent, WebsocketService } from 'websocket';
import { ScheduleService } from 'schedule';
import { NotificationService } from 'notification';
import { PinState } from './pin.state';
import { PinQueryResult, Qr } from './pin.type';
import { LoadingService } from 'loading';
import { AuthService } from 'auth';

@Injectable({
  providedIn: 'root',
})
export class PinResolver {
  public readonly websocketService = inject(WebsocketService);
  public readonly scheduleService = inject(ScheduleService);
  public readonly notificationService = inject(NotificationService);
  public readonly loadingService = inject(LoadingService);
  public readonly authService = inject(AuthService);
  public readonly state = inject(PinState);
  public readonly router = inject(Router);

  public getStaff(callback: () => void) {
    this.loadingService.show();
    this.state.staff().data.set(undefined);
    this.state.staff.update((data) => ({ ...data, loading: true }));

    this.websocketService
      .query<PinQueryResult>(this.state.queries())
      .subscribe(({ event, data }) => {
        this.loadingService.hide();
        this.state.staff.update((data) => ({ ...data, loading: false }));

        if (event === PusherEvent.ERROR) {
          this.notificationService.error(data.message as string);
          callback();
          return;
        }

        if (!data[this.state.model().model]) {
          this.notificationService.error('Staff not found');
          callback();
          return;
        }

        if (this.state.model().model === 'StaffQr') {
          if (!data[this.state.model().model].staff) {
            this.notificationService.error('QR not assigned');
            callback();
            return;
          }

          this.getPinFromQr(data[this.state.model().model] as Qr);
          return;
        }

        this.state.setStaff(data);
        this.router.navigate(['/uniform']);
      });
  }

  public getPinFromQr(qr: Qr) {
    this.state.pin.set({
      type: qr.staff_type === 'contractor' ? 'contractor_staff' : 'staff',
      value: qr.staff_id.toString(),
    });

    this.getStaff(() => {});
  }

  public logout() {
    this.authService.logout();
    this.state.job.set(undefined);
    this.authService.storage.remove('job');
  }

  public hasJob() {
    const job = this.authService.storage.get('job');

    if (!job) {
      this.logout();

      return false;
    }

    this.state.job.set(job);

    return true;
  }
}
