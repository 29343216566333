export const SHIFT_TOTALS = [
  'shift_links_sia as sia_total',
  'shift_links_stewards as stewards_total',
  'shift_links_supervisors as supervisors_total',
];

export const REGULAR_SHIFT = {
  fields: [
    'id',
    'date',
    'start_time',
    'end_time',
    'stewards',
    'sia',
    'supervisors',
    'sign_off',
  ],
  with: [
    'shift_links:id,shift_id,staff_id,role,accepted',
    'shift_links.user:id,full_name',
  ],
};

export const CONTRACTOR_SHIFT = {
  fields: [
    'id',
    'job_id',
    'contractor_id',
    'date',
    'stewards',
    'sia',
    'supervisor',
    'sign_off',
    'position',
  ],
  with: [
    'shift_links:id,move_to_shift_id,contractor_staff_id,role,accepted',
    'shift_links.user:id,first_name,last_name',
  ],
};

export const STAMBRIDGE_SHIFT = {
  fields: [
    'id',
    'job_id',
    'date',
    'stewards',
    'sia',
    'supervisor',
    'sign_off',
    'position',
  ],
  with: [
    'shift_links:id,move_to_shift_id,staff_id,role,accepted',
    'shift_links.user:id,full_name',
  ],
};
