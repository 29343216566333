import {
  CONTRACTOR_SHIFT,
  REGULAR_SHIFT,
  SHIFT_TOTALS,
  STAMBRIDGE_SHIFT,
} from './schedule.query';
import { ShiftModels, ShiftRoles } from './schedule.type';

export const TOTALS_MODEL: ShiftRoles = {
  sia: 0,
  stewards: 0,
  supervisors: 0,
  total: 0,
};

export const SHIFT_MODEL: ShiftModels = {
  regular: {
    model: 'Shift',
    shiftLinkType: 'regular',
    query: REGULAR_SHIFT,
    lazyQuery: {
      withCount: SHIFT_TOTALS,
      fields: ['id'],
      with: [],
    },
  },
  contractor: {
    model: 'ContractorShiftPending',
    shiftLinkType: 'contractor_block',
    query: CONTRACTOR_SHIFT,
    lazyQuery: {
      withCount: SHIFT_TOTALS,
      fields: ['id'],
      with: [],
    },
  },
  stambridge: {
    model: 'ShiftPending',
    shiftLinkType: 'stambridge_block',
    query: STAMBRIDGE_SHIFT,
    lazyQuery: {
      fields: ['id'],
      withCount: SHIFT_TOTALS,
      with: [],
    },
  },
};
