import { WritableSignal } from '@angular/core';
import { QueryUnique } from 'websocket';

export type ShiftLinkType =
  | 'regular'
  | 'contractor'
  | 'contractor_block'
  | 'stambridge_block';

export interface ShiftLink {
  id: number;
  type: ShiftLinkType;
  data: ShiftLinkData;
  hidden?: boolean;
}

export interface Shift {
  id: number;
  date: string;
  start_time: string;
  end_time: string;
  job_id?: number;
  shift_links?: WritableSignal<ShiftLink>[];
  contractor_shift_links?: WritableSignal<ShiftLink>[];
  contractor?: ShiftContractor;
  sia: number;
  stewards: number;
  supervisors: number;
  supervisor?: number;
  sia_total?: number;
  stewards_total?: number;
  supervisors_total?: number;
  unassigned?: string[];
  reference?: string;
}

export interface ShiftRoles {
  sia: number;
  stewards: number;
  supervisors: number;
  total: number;
}

export type ShiftRole = keyof ShiftRoles;

export interface ShiftTotals {
  total: ShiftRoles;
  required: ShiftRoles;
}

export type ShiftMetaType = '0' | '1' | '' | undefined;

export interface ShiftMeta {
  accepted?: ShiftMetaType;
  hotel?: ShiftMetaType;
  meals?: ShiftMetaType;
  on_site?: ShiftMetaType;
  transport?: ShiftMetaType;
  subs?: { name: string; amount: number }[];
}

export type ShiftMetaKey = keyof ShiftMeta | 'email';

export interface EventShiftMetaChange {
  key: ShiftMetaKey;
  value?: string;
}

export interface ShiftMetaIcon {
  key: ShiftMetaKey;
  icon: string;
}

export interface ShiftLinkData extends ShiftMeta {
  id: number;
  staff_id?: number;
  contractor_staff_id?: number;
  role: string;
  start_id: number;
  end_id: number;
  user?: {
    id: number;
    full_name: string;
    personal?: {
      star_rating: number;
    };
    qr?: {
      code: string;
    };
  };
  clock_in?: ShiftClocker;
  clock_out?: ShiftClocker;
  supervisor?: boolean;
  absent?: boolean;
  do_not_contact?: boolean;
  overtime_shift?: boolean;
  notes?: string;
  staff_notes?: string;
  reference?: string;
  location?: string;
  overtime: ShiftOvertimeRequest;
}

export interface ShiftClocker {
  id: number;
  time: string;
  original_time: string;
  image: string;
}

export interface ShiftClockTime {
  id: number;
  clock_in?: ShiftClocker;
  clock_out?: ShiftClocker;
}

export interface EventShiftClockerChange {
  type: ShiftClockType;
  value: string;
}

export type ShiftClockType =
  | ShiftClockTypes.clock_in
  | ShiftClockTypes.clock_out;

export enum ShiftClockTypes {
  clock_in = 'clock_in',
  clock_out = 'clock_out',
}

export interface ShiftOvertimeRequest {
  hours: string;
  id: string;
  minutes: string;
  reason: string;
  shift_id: string;
  staff_id: string;
  status: string;
}

export interface ShiftLinkQueryResult {
  [model: string]: ShiftLinkData;
}

export interface ShiftResponse extends Omit<Shift, 'shift_links'> {
  shift_links?: ShiftLinkData[];
}

export interface ShiftContractor {
  id: number;
  name: string;
}

export type ShiftType = 'regular' | 'contractor' | 'stambridge';

export type ShiftModel = {
  model: string;
  shiftLinkType: ShiftLinkType;
  query: Omit<QueryUnique, 'id'>;
  lazyQuery?: Omit<QueryUnique, 'id'>;
};

export type ShiftModels = {
  [key in ShiftType]: ShiftModel;
};

export interface ShiftFilter {
  search?: string;
}

export interface ShiftUI {
  id: number;
  toggle?: boolean;
  searchValue?: string;
  data: Shift;
}
